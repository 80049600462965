
import { defineComponent, reactive, ref } from 'vue';
import store from '@/store';
import { Error } from '@/models/error';
import { IMessageSendRequest } from '@/models/mail';
import Spinner from '@/components/atoms/Spinner.vue';

export default defineComponent({
  components: {
    Spinner,
  },
  setup: () => {
    const request: IMessageSendRequest = reactive({ name: '', address: '', comment: '' });
    const error = ref('');
    const success = ref('');
    const sending = ref(false);

    const sendClicked = async () => {
      error.value = '';
      success.value = '';
      sending.value = true;
      const response = await store.mail.send(request);

      if (response.data instanceof Error) {
        error.value = response.data.message;
        sending.value = false;
        return;
      }

      success.value = response.data.message;
      sending.value = false;
      request.name = '';
      request.address = '';
      request.comment = '';
    };

    return {
      error,
      sending,
      request,
      sendClicked,
      success,
    };
  },
});
