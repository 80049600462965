<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import store from '@/store';
import { Error } from '@/models/error';
import { IMessageSendRequest } from '@/models/mail';
import Spinner from '@/components/atoms/Spinner.vue';

export default defineComponent({
  components: {
    Spinner,
  },
  setup: () => {
    const request: IMessageSendRequest = reactive({ name: '', address: '', comment: '' });
    const error = ref('');
    const success = ref('');
    const sending = ref(false);

    const sendClicked = async () => {
      error.value = '';
      success.value = '';
      sending.value = true;
      const response = await store.mail.send(request);

      if (response.data instanceof Error) {
        error.value = response.data.message;
        sending.value = false;
        return;
      }

      success.value = response.data.message;
      sending.value = false;
      request.name = '';
      request.address = '';
      request.comment = '';
    };

    return {
      error,
      sending,
      request,
      sendClicked,
      success,
    };
  },
});
</script>

<template>
  <div>
    <Spinner v-if="sending" />
    <section v-else :class="$style.component">
      <h2 :class="$style.title">お問い合わせ</h2>
      <p v-if="typeof error === 'string'" :class="$style.error">{{ error }}</p>
      <p v-if="success" :class="$style.success">{{ success }}</p>
      <form :class="$style.form" @submit.prevent action="/" method="GET">
        <label for="name">
          名前:
          <p v-for="(message, i) in error.name" :key="i" :class="$style.error">{{ message }}</p>
          <input type="text" id="name" name="name" :class="$style.input" v-model="request.name" />
        </label>
        <label for="address">
          メールアドレス:
          <p v-for="(message, i) in error.address" :key="i" :class="$style.error">{{ message }}</p>
          <input type="text" id="address" name="address" :class="$style.input" v-model="request.address" />
        </label>
        <label for="comment">
          本文:
          <p v-for="(message, i) in error.comment" :key="i" :class="$style.error">{{ message }}</p>
          <textarea id="comment" :class="$style.textarea" rows="10" v-model="request.comment"></textarea>
        </label>
        <button :class="$style.send_button" @click="sendClicked">送信</button>
      </form>
    </section>
  </div>
</template>

<style lang="scss" module>
.component {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: $color-light;

  h2 {
    &.title {
      margin: 0 auto;
      text-align: center;
      border-bottom: solid 1px #aaa;
    }
  }

  .error {
    margin: 0;
    color: #dc3232;
  }

  .success {
    margin: 0;
    color: #46b450;
  }

  .form {
    display: flex;
    flex-direction: column;

    & > label {
      margin: 8px;
    }

    .input {
      outline: none;
      box-sizing: border-box;
      height: 30px;
      margin: 0 0;
      padding: 8px;
      display: block;
      max-width: 300px;
      width: 100%;
    }

    .textarea {
      outline: none;
      box-sizing: border-box;
      margin: 0 0;
      padding: 8px;
      display: block;
      width: 100%;
    }

    .send_button {
      outline: none;
      box-sizing: border-box;
      margin: 0 0;
      padding: 8px;
      display: block;
      width: fit-content;
      font-size: 18px;
      line-height: 1;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
